export type DrupalUser = {
  fetched: boolean
  accessToolbar: boolean
  showFrontendTools: boolean
}

export default function () {
  return useState<DrupalUser>('drupalUser', () => ({
    fetched: false,
    accessToolbar: false,
    showFrontendTools: false,
  }))
}
