import { defineNuxtPlugin } from '#app/nuxt'
import { LazyVuepalLocalTasks, LazyVuepalAdminToolbar, LazyVuepalRemoteVideo, LazyVuepalLink, LazyVuepalTransitionHeight, LazyRokkaImage, LazyNuxtPageDependency, LazySpriteSymbol, LazyRenderCacheable, LazyBlokkliField, LazyBlokkliEditable, LazyBlokkliProvider, LazyBlokkliItem } from '#components'
const lazyGlobalComponents = [
  ["VuepalLocalTasks", LazyVuepalLocalTasks],
["VuepalAdminToolbar", LazyVuepalAdminToolbar],
["VuepalRemoteVideo", LazyVuepalRemoteVideo],
["VuepalLink", LazyVuepalLink],
["VuepalTransitionHeight", LazyVuepalTransitionHeight],
["RokkaImage", LazyRokkaImage],
["NuxtPageDependency", LazyNuxtPageDependency],
["SpriteSymbol", LazySpriteSymbol],
["RenderCacheable", LazyRenderCacheable],
["BlokkliField", LazyBlokkliField],
["BlokkliEditable", LazyBlokkliEditable],
["BlokkliProvider", LazyBlokkliProvider],
["BlokkliItem", LazyBlokkliItem],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
