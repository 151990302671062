<template>
  <NuxtLink v-if="count" :to="{ name: 'cart' }" class="button is-sm group">
    <SpriteSymbol name="cart" class="-translate-y-1" aria-hidden="true" />
    {{ $texts('cart', 'Warenkorb') }}
    <div
      class="px-3 min-w-[19px] rounded-full text-center text-xs bg-purple-500 text-white -mr-5 group-hover:bg-white group-hover:text-blue-800 -my-3 h-[19px] leading-[20px] font-bold"
    >
      {{ count }}
    </div>
  </NuxtLink>
</template>

<script lang="ts" setup>
import { useCartStore } from '~/stores/cart'

const cart = useCartStore()
const count = computed(() => cart.count)
</script>
