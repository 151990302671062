<template>
  <figure v-if="image">
    <div :class="{ 'overflow-hidden rounded': rounded }" class="bg-gray-100">
      <FieldItemTypeImage
        v-bind="image"
        :config="imageStyle"
        :loading="loading"
        :preload="preload"
        :img-class="imgClass"
      />
    </div>
    <figcaption
      v-if="!hideCaption && (caption || copyright)"
      class="text-xs md:flex justify-between mt-10"
    >
      <div v-if="caption">{{ caption }}</div>
      <div v-if="copyright" class="text-gray-300 mt-5 md:mt-0">
        &copy; {{ copyright }}
      </div>
    </figcaption>
  </figure>
</template>

<script lang="ts" setup>
import type { DefineImageStyleConfig } from '#rokka/types'
import type { MediaImageFragment } from '#graphql-operations'

withDefaults(
  defineProps<{
    caption?: MediaImageFragment['caption']
    copyright?: MediaImageFragment['copyright']
    image?: MediaImageFragment['image']
    imageStyle: DefineImageStyleConfig | 'header' | 'dynamic/noop'
    imgClass?: string
    hideCaption?: boolean
    preload?: boolean
    loading?: 'lazy' | 'eager'
    rounded?: boolean
  }>(),
  {
    caption: '',
    copyright: '',
    image: undefined,
    imageStyle: 'header',
    imgClass: '',
    hideCaption: false,
    preload: false,
    rounded: false,
    loading: 'lazy',
  },
)
</script>

<script lang="ts">
export default {
  name: 'MediaImage',
}
</script>
