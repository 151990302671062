<template>
  <RenderCacheable
    :max-age="60 * 60"
    :cache-tags="['nuxt:component:footer']"
    :cache-key="language"
  >
    <PageFooter :langcode="langcode" />
  </RenderCacheable>
</template>

<script setup lang="ts">
import { Langcode } from '#graphql-operations'

const language = useCurrentLanguage()

const langcode = computed<Langcode>(() => {
  switch (language.value) {
    case 'fr':
      return Langcode.FR
    case 'it':
      return Langcode.IT
    case 'en':
      return Langcode.EN
    case 'ls':
      return Langcode.LS
  }

  return Langcode.DE
})

useCDNHeaders((v) => v.addTags(['nuxt:component:footer']))
</script>
