<template>
  <header class="page-header grid items-center relative container is-wide">
    <h1 class="sr-only">
      {{ $texts('srLandmarks.mainNavigation', 'Hauptnavigation') }}
    </h1>
    <LayoutHeaderLogo
      class="grid-area-l relative z-30"
      :is-bettingen="isBettingen"
      :is-environment-report="isEnvironmentReport"
      :is-metropolitan-conference="isMetropolitanConference"
      :is-implementation-of-care-initiative="isImplementationOfCareInitiative"
      :is-training-contributions-for-nursing-care="
        isTrainingContributionsForNursingCare
      "
      :language="language"
    />

    <div class="grid-area-m flex gap-10 justify-end">
      <Transition name="page-header-mobile" :duration="500">
        <div
          v-show="mobileMenuOpen"
          class="flex flex-row gap-10 justify-end items-center absolute top-0 bg-white pt-60 pb-[14px] inset-x-0 md:!flex w-auto px-15 z-20 md:static md:size-auto md:p-0 md:overflow-visible"
        >
          <LayoutHeaderLanguageSwitcher :language="language" />
          <div>
            <a :href="eKontoUrl" class="button is-sm is-link" target="_blank">
              {{ $texts('header.eKontoLinkName', 'eKonto') }}
              <span class="sr-only">
                {{
                  $texts(
                    'accessibility.externalLinkText',
                    'Externer Link, wird in einem neuen Tab oder Fenster geöffnet',
                  )
                }}
              </span>
            </a>
          </div>

          <ClientOnly>
            <MetaNavCart />
          </ClientOnly>
        </div>
      </Transition>
      <VuepalLink
        :to="searchUrl"
        class="button is-icon-only is-sm relative z-30"
      >
        <span class="sr-only">
          {{
            $texts(
              'searchButtonText',
              'Öffnet die Suchseite und fokussiert automatisch das Suchfeld',
            )
          }}
        </span>
        <SpriteSymbol name="search" aria-hidden="true" />
      </VuepalLink>
      <button
        class="button is-sm md:!hidden relative z-20 !px-5 py-0 !items-center flex justify-center !gap-0"
        :class="{
          'is-strong': mobileMenuOpen,
        }"
        @click.prevent="mobileMenuOpen = !mobileMenuOpen"
      >
        <SpriteSymbol v-if="mobileMenuOpen" name="close" aria-hidden="true" />
        <SpriteSymbol
          v-else
          name="dots"
          aria-hidden="true"
          class="translate-y-1"
        />
        <ClientOnly>
          <LayoutHeaderCartCount :menu-open="mobileMenuOpen" />
        </ClientOnly>
      </button>
    </div>

    <div
      v-if="!isBettingen"
      class="grid-area-n flex justify-center relative z-10"
    >
      <LayoutHeaderConsole
        :active-menu="activeMenu"
        class="transition md:transition-none duration-300 ease-swing will-change-transform"
        :class="{
          '-translate-x-30 opacity-0': mobileMenuOpen,
        }"
      />
    </div>
  </header>
</template>

<script setup lang="ts">
import type { PageLanguage } from '#language-negotiation/language'

defineProps<{
  cacheKey: string
  isBettingen: boolean
  isEnvironmentReport: boolean
  isMetropolitanConference: boolean
  isImplementationOfCareInitiative: boolean
  isTrainingContributionsForNursingCare: boolean
  activeMenu: ActiveMenuKey | null
  language: PageLanguage
}>()

const router = useRouter()
const searchUrl = computed(() => router.resolve({ name: 'search' }).href)

const eKontoUrl = 'https://ekonto.egov.bs.ch'

const mobileMenuOpen = ref(false)

onRouteChange(function () {
  mobileMenuOpen.value = false
})

defineOptions({
  name: 'LayoutHeader',
})
</script>

<style lang="postcss">
:root {
  --page-header-top-height: 46px;
  --page-header-bottom-height: 58px;
  --page-header-height: calc(
    var(--page-header-top-height) + var(--page-header-bottom-height)
  );
  @screen lg {
    --page-header-bottom-height: 0px;
    --page-header-top-height: 70px;
  }
}

html.is-site-bettingen {
  --page-header-bottom-height: 0px;
}

.page-header {
  height: var(--page-header-height);
  grid-template: 'L . M' 'N N N';
  grid-template-rows: 46px 1fr;

  @screen lg {
    grid-template: 'L N M';
    grid-template-rows: auto;
    @apply grid-cols-3;
  }
}

.page-header-mobile-enter-active,
.page-header-mobile-leave-active {
  @apply transition duration-300 ease-swing overflow-hidden;
  @media (prefers-reduced-motion) {
    @apply !transition-none;
  }

  > div,
  > a {
    @apply transition duration-300 ease-swing;
  }
}

.page-header-mobile-enter-from,
.page-header-mobile-leave-to {
  @apply opacity-0;
  > div,
  > a {
    @apply translate-x-50;
  }
}
</style>
