import type { Pinia } from 'pinia'
import { defineNuxtPlugin } from '#app'
import { useCartStore } from '@/stores/cart'

/**
 * Load the cart for the current session.
 */
export default defineNuxtPlugin((nuxtApp) => {
  const cartLoaded = useState('cart_loaded', () => false)

  // Cart is already loaded.
  if (cartLoaded.value) {
    return
  }
  if (import.meta.server) {
    cartLoaded.value = true
    return
  }

  const cart = useCartStore(nuxtApp.$pinia as Pinia)
  if (!cart) {
    return
  }

  cart.loadCart()
})
