import { defineStore } from 'pinia'
import { useNotifications } from '@/stores/notifications'
import type {
  AddToCartMutation,
  CartChangeQuantityMutation,
  CartFragment,
  CartOrderItemFragment,
  CartSummaryAdjustmentFragment,
  RemoveFromCartMutation,
  PlaceOrderMutation,
  BaselStadtCustomerAddressInput,
} from '#graphql-operations'

type PossibleMutations =
  | AddToCartMutation
  | RemoveFromCartMutation
  | CartChangeQuantityMutation
  | PlaceOrderMutation

interface State {
  id: string
  orderItems: CartOrderItemFragment[]
  subtotal: string
  total: string
  adjustments: CartSummaryAdjustmentFragment[]
}

export const useCartStore = defineStore({
  id: 'cart',
  state: (): State => {
    return {
      id: '',
      orderItems: [],
      subtotal: '',
      total: '',
      adjustments: [],
    }
  },
  actions: {
    updateCart(cart: CartFragment) {
      this.id = cart.id || ''
      this.orderItems = cart.orderItems || []
      this.subtotal = cart.orderTotalSummary?.subtotal?.formatted || ''
      this.total = cart.orderTotalSummary?.total?.formatted || ''
      this.adjustments = cart.orderTotalSummary?.adjustments || []
    },
    updateFromMutation(mutation: PossibleMutations | undefined) {
      if (!mutation?.result?.success) {
        const result = mutation?.result
        const errors = result && 'errors' in result ? result.errors : []
        if (errors && errors.length) {
          const notify = useNotifications()
          errors.forEach((v) => notify.error(v))
        }
      }

      const cart = mutation?.result?.order
      if (cart) {
        this.updateCart(cart)
      }

      return !!mutation?.result?.success
    },
    loadCart() {
      return useGraphqlQuery('getCart').then((v) => {
        if (v.data.commerceGetCart) {
          this.updateCart(v.data.commerceGetCart)
        }
      })
    },
    addProduct(productVariationId: string, quantity: number = 1) {
      return useGraphqlMutation('addToCart', {
        id: productVariationId,
        quantity,
      }).then((v) => this.updateFromMutation(v.data))
    },
    placeOrder(
      mail: string,
      shippingAddress: BaselStadtCustomerAddressInput,
      billingAddress?: BaselStadtCustomerAddressInput,
    ) {
      return useGraphqlMutation('placeOrder', {
        mail,
        produceShippingAddress: shippingAddress,
        produceBillingAddress: billingAddress || undefined,
      }).then((v) => v.data)
    },
    removeProduct(productVariationId: string) {
      const orderItem = this.orderItems.find(
        (v) => v.purchasedEntity?.id === productVariationId,
      )
      if (!orderItem?.id) {
        return Promise.reject()
      }

      return this.removeOrderItem(orderItem.id)
    },
    changeQuantity(orderItemId: string, quantity: number) {
      return useGraphqlMutation('cartChangeQuantity', {
        id: orderItemId,
        quantity,
      }).then((v) => this.updateFromMutation(v.data))
    },
    removeOrderItem(orderItemId: string) {
      return useGraphqlMutation('removeFromCart', { id: orderItemId }).then(
        (v) => this.updateFromMutation(v.data),
      )
    },
  },
  getters: {
    count: (state: State) => state.orderItems.length,
    isInCart: (state) => {
      return (productVariationId: string) =>
        !!state.orderItems.find(
          (v) => v.purchasedEntity?.id === productVariationId,
        )
    },
  },
})
