<template>
  <AppTop />
  <div
    class="relative flex items-end justify-center overflow-hidden h-[calc(100vh-var(--page-header-height))]"
  >
    <h1
      class="container relative z-30 w-full text-white text-5xl font-bold mb-40"
    >
      {{ $texts('pageNotFound', 'Seite nicht gefunden') }}
    </h1>

    <video
      loop
      muted
      class="motion-reduce:hidden absolute z-10 w-auto min-w-full min-h-full object-cover"
      aria-hidden="true"
      autoplay
      @click.prevent="toggle"
    >
      <source src="~/assets/videos/404.mp4" type="video/mp4" />
    </video>
  </div>
  <AppBottom />
</template>

<script lang="ts" setup>
const videoPlayer = ref<HTMLVideoElement | null>(null)

function toggle() {
  if (!videoPlayer.value) {
    return
  }

  if (videoPlayer.value.paused) {
    videoPlayer.value.play()
  } else {
    videoPlayer.value.pause()
  }
}
</script>
