export default defineNuxtPlugin(async ({ vueApp }) => {
  // Only run this plugin in dev mode.
  if (import.meta.dev) {
    // We have to import the module this way so it doesn't end up in the
    // client bundle.
    // @ts-ignore
    const axe = await import('vue-axe')
    vueApp.use(axe.default)
    vueApp.component('VueAxePopup', axe.VueAxePopup)
  }
})
