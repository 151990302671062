import routerOptions0 from "/builds/bazinga/bs.ch/frontend/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/builds/bazinga/bs.ch/frontend/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}