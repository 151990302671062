interface CurrentSiteContext {
  isBettingen: ComputedRef<boolean>
  isEnvironmentReport: ComputedRef<boolean>
  isMetropolitanConference: ComputedRef<boolean>
  isImplementationOfCareInitiative: ComputedRef<boolean>
  isTrainingContributionsForNursingCare: ComputedRef<boolean>
}

export function useCurrentSite(): CurrentSiteContext {
  const route = useRoute()
  const isBettingen = computed(() => {
    return route.path.startsWith('/bettingen')
  })

  const isEnvironmentReport = computed(() => {
    return route.path.includes('umweltbericht')
  })

  const isMetropolitanConference = computed(() => {
    return route.path.includes('metropolitankonferenz')
  })

  const isImplementationOfCareInitiative = computed(() => {
    return route.path.includes('umsetzung-pflegeinitiative')
  })

  const isTrainingContributionsForNursingCare = computed(() => {
    return route.path.includes('ausbildungsbeitraege-pflege')
  })

  return {
    isBettingen,
    isEnvironmentReport,
    isMetropolitanConference,
    isImplementationOfCareInitiative,
    isTrainingContributionsForNursingCare,
  }
}
