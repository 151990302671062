<template>
  <div
    class="rounded-large ring-gray-700/20 ring-1 hover:ring-gray-700/60"
    :class="[
      isGreen ? 'bg-primary-50' : 'bg-white',
      {
        'shadow-lg !ring-gray-700/60': isOpen,
      },
    ]"
  >
    <h3
      class="rounded-large cursor-pointer text-blue-900 hover:text-blue-700 md:text-lg lg:text-xl font-bold"
    >
      <button
        :id="idButton"
        type="button"
        class="flex items-center justify-between marker:hidden p-10 w-full md:px-20 lg:py-15 text-left focus-visible:outline-2"
        :aria-expanded="isOpen"
        :aria-controls="idContent"
        @click.prevent="isOpenByClick = !isOpenByClick"
      >
        <slot name="title">
          {{ title }}
        </slot>
        <SpriteSymbol
          name="caret"
          class="transition-all duration-250 w-15 h-10 shrink-0"
          aria-hidden="true"
          :class="{
            '-scale-y-100': isOpen,
          }"
        />
      </button>
    </h3>
    <VuepalTransitionHeight opacity>
      <div
        v-show="isOpen"
        :id="idContent"
        role="region"
        :aria-labelledby="idButton"
      >
        <div class="p-10 md:p-20">
          <slot />
        </div>
      </div>
    </VuepalTransitionHeight>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  title: string
  isGreen?: boolean

  /**
   * Force the accordion to be always open.
   *
   * We mostly use this in the editor, so that the contents of the accordion
   * are visible.
   */
  alwaysOpen?: boolean
}>()

const id = useId()

const idButton = computed(() => 'accordion-toggle-' + id)
const idContent = computed(() => 'accordion-content-' + id)

const { isPrinting } = useViewport()

const isOpenByClick = ref(false)

const isOpen = computed(
  () => props.alwaysOpen || isPrinting.value || isOpenByClick.value,
)
</script>
