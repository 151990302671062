<template>
  <div :key="language" class="bk-main-canvas">
    <RouteAnnouncer />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import type { Script } from '@unhead/schema'
import { MAX_AGE } from '~/server/helpers'

declare module './node_modules/nuxt/dist/pages/runtime/composables' {
  interface PageMeta {
    ariaLive?: 'off' | 'polite' | 'assertive' | undefined
  }
}

const drupalUser = useDrupalUser()
const language = useCurrentLanguage()

const currentUrl = useRequestURL()
const { isAcceptAll } = useConsentCookie()

const { isBettingen, isEnvironmentReport, isMetropolitanConference } =
  useCurrentSite()

const headClass = computed(() => {
  return isBettingen.value ? 'is-site-bettingen' : ''
})

const script = computed<Script[]>(() => {
  if (!isAcceptAll.value || currentUrl.host !== 'www.bs.ch') {
    return []
  }

  return [
    {
      src: 'https://siteimproveanalytics.com/js/siteanalyze_6027199.js',
      async: true,
    },
  ]
})

useHead({
  htmlAttrs: {
    tagDuplicateStrategy: 'merge',
    lang: language.value,
    class: headClass,
  },
  script,
})

onMounted(() => {
  if (!drupalUser.value.accessToolbar || window.location.hash.length === 0) {
    return
  }

  // We need to manually scroll hash elements into view, since
  // vue-router is trying to, but the async nature of Blokkli
  // kind-of prohibits that.
  try {
    const scrollTarget = document.querySelector(window.location.hash)
    if (scrollTarget) {
      scrollTarget.scrollIntoView()
    }
  } catch (_) {
    // noop because invalid selector, likely anura-webcomponents-based.
  }
})

function getCdnNuxtPageValue() {
  if (isBettingen.value) {
    return 'nuxt:context:bettingen'
  }

  if (isEnvironmentReport.value) {
    return 'nuxt:context:environment_report'
  }

  if (isMetropolitanConference.value) {
    return 'nuxt:context:metropolitan_conference'
  }

  return 'nuxt:context:bs'
}

// Set default CDN cacheability for all pages.
useCDNHeaders((v) =>
  v
    .set('staleIfError', MAX_AGE.ONE_DAY)
    .set('staleWhileRevalidate', MAX_AGE.TWO_HOURS)
    .set('mustRevalidate', true)
    .addTags(['nuxt:page', getCdnNuxtPageValue()]),
)
</script>
