<template>
  <main>
    <div v-if="props.error?.statusCode === 404">
      <PageNotFound />
    </div>
    <NuxtLayout v-else name="minimal">
      <header>
        <ContentHeader :title="title" :lead="message" />
      </header>
      <div class="mt-80">
        <div class="container mx-auto">
          <Accordion title="Technische Details">
            <div class="overflow-auto bg-gray-50 p-15">
              <code v-html="error?.stack" />
            </div>
          </Accordion>
        </div>
      </div>
    </NuxtLayout>
  </main>
</template>

<script setup lang="ts">
type ErrorObject = {
  url?: string
  statusCode?: number
  statusMessage?: string
  message?: string
  stack?: string
}

const props = defineProps<{
  error: ErrorObject
}>()

const serverError: Record<string, string> = {
  de: 'Interner Serverfehler',
  fr: 'Erreur interne du serveur',
  it: 'Errore interno del server',
  en: 'Internal Server Error',
}

const serverErrorMessage: Record<string, string> = {
  de: 'Die Seite ist aktuell aufgrund eines unerwarteten Fehlers nicht verfügbar. Bitte versuchen Sie es später noch einmal.',
}

const lanuage = useCurrentLanguage()

setActiveMenuKey()

const code = computed(() => props.error.statusCode || 500)

const title = computed(() => {
  const message = serverError[lanuage.value] || serverError.de
  return `${code.value}: ${message}`
})

const message = computed(() => {
  return serverErrorMessage.de
})

useCDNHeaders((cdn) => {
  if (props.error.statusCode === 404) {
    // cache 404 for a short time.
    cdn.setNumeric('maxAge', 60 * 10)
    return
  }

  cdn.private()
})
</script>
