import { useWindowSize, useMediaQuery } from '@vueuse/core'

type UseViewport = {
  isMobile: ComputedRef<boolean>
  isPrinting: Ref<boolean>
}

export default function (): UseViewport {
  const { width } = useWindowSize()
  const isPrinting = useMediaQuery('print')
  const isMobile = computed(() => {
    return import.meta.server || width.value < 768
  })

  return { isMobile, isPrinting }
}
