import type { LoadTranslationsQuery } from '#build/graphql-operations'
import { defineEasyTextsLoader } from '#nuxt-easy-texts/types'

type Translations = Record<string, string | [string, string]>

function getTranslations(v?: LoadTranslationsQuery): Translations {
  if (!v) {
    return {}
  }
  return Object.entries(
    (v.translations || {}) as Record<
      string,
      string | { singular?: string; plural?: string }
    >,
  ).reduce<Record<string, string | [string, string]>>(
    (acc, [fullKey, value]) => {
      const keyWithDots = fullKey.replace('__', '.')
      if (typeof value === 'string') {
        acc[keyWithDots] = value
      } else if (typeof value === 'object' && value.plural && value.singular) {
        acc[keyWithDots] = [value.singular, value.plural]
      }
      return acc
    },
    {},
  )
}

export default defineEasyTextsLoader(() => {
  const language = useCurrentLanguage()
  const event = useRequestEvent()
  return {
    async load() {
      // During prerendering, we don't have Drupal available. The prerendered
      // pages don't need any texts, so this is fine.
      if (import.meta.prerender) {
        return {}
      }

      const { value: cachedData, addToCache } =
        await useDataCache<Translations>(
          'translations-' + language.value,
          event,
        )

      if (cachedData) {
        return cachedData
      }

      const data = await useGraphqlQuery('loadTranslations')
      const translations = getTranslations(data.data)

      await addToCache(translations)

      return translations
    },
    reloadTrigger() {
      return computed(() => language.value)
    },
  }
})
