import { useLocalStorage } from '@vueuse/core'

export enum CookieConsentValue {
  /**
   * No cookies are accepted.
   */
  None = '0',

  /**
   * All cookies are accepted.
   */
  AcceptAll = '1',

  /**
   * Only functional/required cookies accepted.
   */
  AcceptPartial = '2',
}

type UseConsentCookie = {
  isAcceptAll: ComputedRef<boolean>
  isPartiallyAccepted: ComputedRef<boolean>
  mustDecide: ComputedRef<boolean>
  acceptAll: () => void
  acceptPartial: () => void
}

export function useConsentCookie(): UseConsentCookie {
  const state = useLocalStorage<CookieConsentValue | undefined>(
    'bs_consent_cookie',
    () => undefined,
  )

  function acceptAll() {
    state.value = CookieConsentValue.AcceptAll
  }

  function acceptPartial() {
    state.value = CookieConsentValue.AcceptPartial
  }

  const isAcceptAll = computed(
    () => state.value === CookieConsentValue.AcceptAll,
  )

  const isPartiallyAccepted = computed(
    () => state.value === CookieConsentValue.AcceptPartial,
  )

  const mustDecide = computed(() => state.value === undefined)

  return {
    acceptAll,
    acceptPartial,
    isPartiallyAccepted,
    isAcceptAll,
    mustDecide,
  }
}
