
export const DPR = ["","1.5","2"]

export const VIEWPORTS = {
  'sm': 480,
  'md': 768,
  'lg': 1024,
  'xl': 1210,
  'xxl': 1920,
  'xxxl': 2400,
  'xxxxl': 3000
} as const

export type Viewport = keyof typeof VIEWPORTS