import { defineNuxtPlugin } from '#app'

/**
 * Loads data about the current session.
 *
 * Only happens during SSR.
 */
export default defineNuxtPlugin(async () => {
  const drupalUser = useDrupalUser()

  // If the config has already been fetched, return.
  // This is the case when we're client-side, because the config is already
  // shipped via payload from the server.
  if (drupalUser?.value.fetched) {
    return
  }

  const headers = useRequestHeaders()
  const hasSessionCookie = (headers.cookie || '').includes('SSESS')

  if (hasSessionCookie || import.meta.client) {
    // Load the information about the Drupal user.
    const { data } = await useGraphqlQuery('drupalUser')
    if (data && data.currentDrupalUser) {
      drupalUser.value.accessToolbar = data.currentDrupalUser.canAccessToolbar
      drupalUser.value.showFrontendTools =
        data.currentDrupalUser.showFrontendTools
    }
  }

  drupalUser.value.fetched = true
})
