<template>
  <RokkaImage
    v-if="hash"
    :hash="hash"
    :config="imageStyle"
    :loading="loading || 'lazy'"
    :preload="preload"
    :source-width="file?.rokka?.sourceWidth"
    :source-height="file?.rokka?.sourceHeight"
    :img-class="imgClass"
    :alt="alt"
  />
</template>

<script setup lang="ts">
import type { DefineImageStyleConfig } from '#rokka/types'
import type { ImageItemFragment } from '#graphql-operations'

const props = defineProps<{
  config: DefineImageStyleConfig | 'header' | 'dynamic/noop'
  file?: ImageItemFragment['file']
  alt?: string
  title?: string
  preload?: boolean
  loading?: 'lazy' | 'eager'
  imgClass?: string | string[] | Record<string, boolean> | undefined
}>()

const hash = computed(() => props.file?.rokka?.hash)

const imageStyleHeader = defineImageStyle({
  type: 'sizes',
  aspectRatio: 16 / 9,
  sizes: {
    sm: 400,
    md: 728,
    lg: 796,
    xl: 1040,
  },
})

const imageStyle = computed<DefineImageStyleConfig | string>(() => {
  if (props.config === 'header') {
    return imageStyleHeader
  } else if (props.config === 'dynamic/noop') {
    return 'dynamic/noop'
  }

  return props.config
})
</script>
