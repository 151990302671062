<template>
  <div class="flex items-center">
    <!-- Logo Bettingen -->
    <VuepalLink
      v-if="isBettingen"
      :to="homeUrl"
      class="block self-center justify-self-start hover:text-primary-900"
    >
      <div class="flex items-center gap-10 md:gap-20">
        <SpriteSymbol name="bettingenkelch" class="h-[33px] w-[18px]" />
        <span
          class="font-bold text-sm leading-tight md:text-base lg:text-lg xl:text-xl"
          >Gemeinde Bettingen</span
        >
      </div>

      <span class="sr-only">
        ({{ $texts('logo.linksToHome', 'Dieser Link führt zur Startseite') }})
      </span>
    </VuepalLink>

    <!-- Logo Environment Report -->
    <VuepalLink
      v-else-if="isEnvironmentReport"
      :to="homeUrl"
      class="inline-block hover:opacity-80"
    >
      <div class="text-base lg:text-lg xl:text-2xl font-bold">
        {{ $texts('environmentReportBothBasel', 'Umweltbericht beider Basel') }}
      </div>

      <span class="sr-only">
        ({{ $texts('logo.linksToHome', 'Dieser Link führt zur Startseite') }})
      </span>
    </VuepalLink>

    <!-- Logo Implementation of care initiative -->
    <VuepalLink
      v-else-if="isImplementationOfCareInitiative"
      :to="homeUrl"
      class="inline-block hover:opacity-80"
    >
      <div class="text-base lg:text-lg xl:text-2xl font-bold">
        {{
          $texts('implementationOfCareInitiative', 'Umsetzung Pflegeinitiative')
        }}
      </div>

      <span class="sr-only">
        ({{ $texts('logo.linksToHome', 'Dieser Link führt zur Startseite') }})
      </span>
    </VuepalLink>

    <!-- Logo training contribution for nursing care -->
    <VuepalLink
      v-else-if="isTrainingContributionsForNursingCare"
      :to="homeUrl"
      class="inline-block hover:opacity-80"
    >
      <div class="text-base lg:text-lg xl:text-2xl font-bold">
        {{
          $texts(
            'trainingContributionsForNursingCare',
            'Ausbildungsbeiträge Pflege',
          )
        }}
      </div>

      <span class="sr-only">
        ({{ $texts('logo.linksToHome', 'Dieser Link führt zur Startseite') }})
      </span>
    </VuepalLink>

    <!-- Logo Metropolitan Conference -->
    <VuepalLink
      v-else-if="isMetropolitanConference"
      :to="homeUrl"
      class="inline-block hover:opacity-80"
    >
      <div class="text-base lg:text-lg xl:text-2xl font-bold">
        {{
          $texts('metropolitanConferenceBasel', 'Metropolitankonferenz Basel')
        }}
      </div>

      <span class="sr-only">
        ({{ $texts('logo.linksToHome', 'Dieser Link führt zur Startseite') }})
      </span>
    </VuepalLink>

    <!-- Logo BS -->
    <VuepalLink v-else :to="homeUrl" class="inline-block hover:opacity-80">
      <Logo class="text-base lg:text-lg xl:text-2xl" />
      <span class="sr-only">
        ({{ $texts('logo.linksToHome', 'Dieser Link führt zur Startseite') }})
      </span>
    </VuepalLink>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  isBettingen: boolean
  isEnvironmentReport: boolean
  isMetropolitanConference: boolean
  isImplementationOfCareInitiative: boolean
  isTrainingContributionsForNursingCare: boolean
  language: string
}>()

const { $texts } = useNuxtApp()

const homeUrl = computed(() => {
  if (props.isBettingen) {
    return '/bettingen'
  }

  if (props.language === 'de') {
    return '/'
  }

  return '/' + props.language
})
</script>
