<template>
  <Logo v-if="isPrinting" />
  <div
    v-else
    tag="div"
    class="border-b border-b-gray-400 bg-white transition ease-in-out duration-250 z-app-top will-change-transform top-0"
    :class="{
      'translate-y-[-46px] md:-translate-y-full': isCollapsed,
      sticky: !isEditing,
    }"
  >
    <RenderCacheable
      :cache-key="cacheKey"
      :async-data-keys="[cacheKey]"
      :max-age="60 * 60"
      :cache-tags="['nuxt:component:header']"
    >
      <LayoutHeader
        :is-bettingen="isBettingen"
        :is-environment-report="isEnvironmentReport"
        :is-metropolitan-conference="isMetropolitanConference"
        :is-implementation-of-care-initiative="isImplementationOfCareInitiative"
        :is-training-contributions-for-nursing-care="
          isTrainingContributionsForNursingCare
        "
        :active-menu="activeMenu"
        :cache-key="cacheKey"
        :language="language"
      />
    </RenderCacheable>
  </div>
</template>

<script lang="ts" setup>
const isCollapsed = ref(false)

const headClass = computed(
  () => 'has-header-' + (isCollapsed.value ? 'hidden' : 'visible'),
)

useHead({
  htmlAttrs: {
    tagDuplicateStrategy: 'merge',
    class: headClass,
  },
})

const route = useRoute()
const language = useCurrentLanguage()
const activeMenu = useActiveMenuKey()
const {
  isBettingen,
  isEnvironmentReport,
  isMetropolitanConference,
  isImplementationOfCareInitiative,
  isTrainingContributionsForNursingCare,
} = useCurrentSite()
const { isPrinting } = useViewport()

const cacheKey = computed(() => {
  const parts = ['LayoutHeader', language.value, activeMenu.value || 'none']

  let section = 'bs'

  if (isBettingen.value) {
    section = 'bettingen'
  }

  if (isEnvironmentReport.value) {
    section = 'environment_report'
  }

  if (isMetropolitanConference.value) {
    section = 'metropolitan_conference'
  }

  parts.push(section)

  return parts.join('::')
})

const isEditing = computed(() => !!route.query.pbEditing)

let lastScrollY = 0

function onScroll() {
  const currentScrollY = window.scrollY
  // Calculate the difference between the last scroll position.
  const diff = currentScrollY - lastScrollY

  if (currentScrollY < 300) {
    // Near the top of the page always show the header.
    isCollapsed.value = false
  } else if (diff > 5) {
    // Scrolling direction is downwards.
    isCollapsed.value = true
  } else if (diff < -5) {
    // Scrolling direction is upwards.
    isCollapsed.value = false
  }

  lastScrollY = currentScrollY
}

onMounted(() => {
  // Setting passive prevents performance issues.
  window.addEventListener('scroll', onScroll, {
    passive: true,
  })
})

useCDNHeaders((v) => v.addTags(['nuxt:component:header']))

onBeforeUnmount(() => {
  window.removeEventListener('scroll', onScroll)
})
</script>
