<template>
  <div v-click-away="closeLanguageSwitcher" class="md:relative">
    <button
      id="language-switcher-button"
      type="button"
      class="button is-sm is-dropdown"
      :aria-label="buttonAria"
      :aria-expanded="languageSwitcherOpen"
      aria-controls="language-switcher-list"
      :class="{
        'is-active': languageSwitcherOpen,
      }"
      @click="languageSwitcherOpen = !languageSwitcherOpen"
    >
      <span
        class="uppercase inline-block min-w-20 text-left"
        :title="currentLanguageLabel"
        >{{ language }}</span
      >
    </button>
    <ul
      v-if="languageSwitcherOpen"
      id="language-switcher-list"
      class="absolute top-full right-0 w-full md:w-220 bg-white md:rounded shadow-lg border-t border-t-gray-200 md:border-t-0"
      role="region"
      aria-labelledby="language-switcher-button"
    >
      <li v-for="(link, index) in linksMapped" :key="index">
        <component
          :is="link.is"
          v-bind="link.attrs"
          class="block px-15 py-10"
          :title="link.title"
          :class="{
            'text-gray-300 cursor-not-allowed': link.disabled,
            link: !link.disabled,
            'font-bold': link.active,
          }"
          :lang="link.lang"
        >
          {{ link.label }}
        </component>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import type { PageLanguage } from '#language-negotiation/language'

const props = defineProps<{
  language: PageLanguage
}>()

const { $texts } = useNuxtApp()

const LANGUAGE_LABEL: Record<PageLanguage, string> = {
  de: 'Deutsch',
  ls: 'Leichte Sprache',
  en: 'English',
  fr: 'Français',
  it: 'Italiano',
}

const currentLanguageLabel = computed(() => LANGUAGE_LABEL[props.language])

const languageLinks = useState<Record<string, string>>(
  'pageLanguageLinksLinks',
  () => ({}),
)

const languageSwitcherOpen = ref(false)

const linksMapped = computed(() => {
  return Object.entries(LANGUAGE_LABEL).map(([langcode, label]) => {
    const link = languageLinks.value[langcode]

    let is: string | object = 'span'
    if (link) {
      if (typeof link === 'string') {
        is = 'a'
      } else {
        // Not a string, but defined, looks like a Nuxt link.
        // In case we have a route object, such as on the landing page, etc.
        is = defineNuxtLink({})
      }
    }

    return {
      is,
      label,
      active: props.language === langcode,
      disabled: !link,
      lang: langcode,
      title: (link
        ? $texts('switchToLanguage', 'Sprache wechseln: @language')
        : $texts(
            'pageNotAvailableInLanguage',
            'Sprache nicht verfügbar: @language',
          )
      ).replace('@language', label),
      attrs: link
        ? {
            href: link,
          }
        : {},
    }
  })
})

const buttonAria = computed(() =>
  $texts(
    'showAvailableLanguagePlusCurrent',
    'Verfügbare Sprachen anzeigen. Aktuelle Sprache ist @language',
  ).replace('@language', currentLanguageLabel.value),
)

function closeLanguageSwitcher() {
  languageSwitcherOpen.value = false
}
</script>
