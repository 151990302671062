<template>
  <NuxtRouteAnnouncer v-slot="{ message }">
    {{ getMessage(message) }}
  </NuxtRouteAnnouncer>
</template>

<script lang="ts" setup>
const navigateToText: Record<string, string> = {
  de: 'Navigiert zu',
  fr: 'Navigué vers',
  it: 'Navigato a',
  en: 'Navigated',
}

const language = useCurrentLanguage()

const prefix = computed<string>(
  () => navigateToText[language.value] || navigateToText.de,
)

function getMessage(message: string): string {
  // A route change announces the title, which always contains this suffix.
  // The default suffix is the long one, but some pages have a custom one set by
  // editors where they halucinated their own suffix.
  if (message.includes('| Kanton Basel-Stadt') || message.includes('| bs.ch')) {
    // Remove this suffix so we don't always announce it.
    const title = message
      .replace('| Kanton Basel-Stadt', '')
      .replace('| bs.ch', '')
    return `${prefix.value}: ${title}`.trim()
  }

  // A custom message.
  return message
}
</script>
