export type ActiveMenuKey = 'topics' | 'organization'

/**
 * Return the active menu key.
 */
export function useActiveMenuKey() {
  return useState<ActiveMenuKey | null>('activeMenuKey', () => null)
}

/**
 * Set the active menu key.
 */
export function setActiveMenuKey(key?: ActiveMenuKey | undefined | null) {
  const activeKey = useActiveMenuKey()
  activeKey.value = key || null
}
