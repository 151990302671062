<template>
  <component :is="tag || 'div'" class="container">
    <slot />
  </component>
</template>

<script lang="ts" setup>
defineProps<{
  tag?: string
}>()
</script>

<script lang="ts">
export default {
  name: 'Container',
}
</script>
