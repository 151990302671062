<template>
  <div class="text-toggle-switch text-lg lg:text-sm xl:text-xl">
    <nav
      class="text-toggle-switch-inner"
      :aria-label="$texts('accessibility.mainNavigation', 'Hauptnavigation')"
    >
      <a
        v-for="(link, index) in links"
        :key="index"
        ref="items"
        :href="link.url"
        :class="{ 'is-active': activeIndex === index }"
        @click.prevent="onClick(link.url, index)"
      >
        <span>{{ link.label }}</span>
      </a>
    </nav>
  </div>
</template>

<script lang="ts" setup>
import type { PageLanguage } from '#language-negotiation/language'

const props = defineProps<{
  activeMenu: ActiveMenuKey | null
}>()

const router = useRouter()
const language = useCurrentLanguage()

const linkRouteNames = ['topics-overview', 'organizations-overview'] as const

const labels: Record<
  (typeof linkRouteNames)[number],
  Record<string, string>
> = {
  'topics-overview': {
    de: 'Themen',
    en: 'Topics',
    fr: 'Thèmes',
    it: 'Temi',
  },
  'organizations-overview': {
    de: 'Organisation',
    it: 'Organizzazione',
  },
}

const routes = router.getRoutes()

const links = computed(() => {
  return linkRouteNames.map((key) => {
    const route = routes.find((v) => v.name === key + '___' + language.value)
    const label = labels[key][language.value] || labels[key].de
    return {
      label,
      url: route?.meta.languageMapping?.[language.value as PageLanguage] || '',
    }
  })
})

// We assume that "Themen" (topics) is always the first item in the menu.
const activeIndex = computed(() => {
  if (props.activeMenu === 'topics') {
    return 0
  } else if (props.activeMenu === 'organization') {
    return 1
  }
  return -1
})

function onClick(url: string, index: number) {
  // Makes sure that the toggle switches immediately when clicking, instead of
  // waiting for the page to load.
  setActiveMenuKey(index === 0 ? 'topics' : 'organization')
  router.push(url)
}
</script>

<style lang="postcss">
@import '~/assets/css/vendor-specific/text-toggle-switch.css';
</style>
