<template>
  <div
    v-if="count"
    class="rounded-full min-w-[17px] min-h-[17px] text-xs leading-none flex items-center justify-center"
    :class="menuOpen ? 'bg-white text-blue-800' : 'bg-purple-600 text-white'"
  >
    {{ count }}
  </div>
</template>

<script setup lang="ts">
import { useCartStore } from '~/stores/cart'

defineProps<{
  menuOpen: boolean
}>()

const cart = useCartStore()
const count = computed(() => cart.count)
</script>
